import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["background"];

  connect() {
    // console.log("intro")
  }

  fade(event){
    event.preventDefault()
    this.backgroundTarget.classList.add("fade")
    document.body.classList.remove("pages--index")
    setTimeout(function(){
      Turbolinks.visit("/projekte", { action: "replace" })
    }, 700)
    
  }
}