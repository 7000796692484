import Swiper, { Navigation, Mousewheel, Pagination } from "swiper";
Swiper.use([Navigation, Mousewheel, Pagination]);
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["nextButton", "prevButton", "slider", "currentPageNumber", "footer"];

  static values = {
    count: Number,
  
  }

  connect() {
    this.swiper = new Swiper(this.sliderTarget, {
      spaceBetween: 0,
      simulateTouch: true,
      width: null,
      loop: true,
      spaceBetween: 10,
      // pagination: {
      //   el: ".swiper-pagination",
      //   type: "bullets",
      //   bulletClass: "pagination__thumbnail",
      //   bulletActiveClass: "active",
      //   clickable: true,
      //   renderBullet: function (index, className) {
      //     const img = this.slides[index].querySelector("img");
      //     return `<span class="${className}" style="background-image: url(${img?.src})" title="Bild ${index}"></span>`
      //   }
      // },
      navigation: {
        nextEl: this.nextButtonTarget,
        prevEl: this.prevButtonTarget,
      },
      mousewheel: {
        forceToAxis: true,
        thresholdDelta: 30,
        thresholdTime: 300
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
    });

    this.swiper.on("realIndexChange", this.onChange.bind(this));
    this.onChange();
  }

  onChange() {
    // this.element.parentElement.dataset.sliderIndex = this.swiper.activeIndex;
    // console.log(this.swiper.realIndex)
    // console.log(this.countValue)
    if (this.countValue == (this.swiper.realIndex + 1)) {
      this.footerTarget.classList.add("hide")
    } else {
      this.footerTarget.classList.remove("hide")
      this.currentPageNumberTarget.innerHTML = this.swiper.realIndex + 1
    }
    
    
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = null;
  }
}